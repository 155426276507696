// import {
//   InMemoryCache,
//   IntrospectionFragmentMatcher,
// } from 'apollo-cache-inmemory';

import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { relayStylePagination } from '@apollo/client/utilities';
// import { split } from 'apollo-link';
import { InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/link/core';
// import { setContext } from 'apollo-link-context';
// import { onError } from 'apollo-link-error';
// import { withClientState } from 'apollo-link-state';
// import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink, ReactNativeFile } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
// import introspectionQueryResultData from 'generated/fragments.json';
import fragments from 'generated/fragments';
import { TypedTypePolicies } from 'generated/type-policies';
import { FirebaseAuthLink } from './FirebaseAuthLink';
import { WsSubscriptionClient } from './WsSubscriptionClient';

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });
const PERSIST_APOLLO_CACHE = false;

export enum ConnectionOnWebsocketMode {
  /** All connections to the backend server pass through the websocket channel */
  ALL = 'ALL',
  /** Pending */
  NONE = 'NONE',
  /** Only subscription  onnections to the backend server pass through the websocket channel*/
  ONLY_SUBSCRIPTIONS = 'ONLY_SUBSCRIPTIONS',
}

const CONNECTION_ON_WEBSOCKET: ConnectionOnWebsocketMode = ((
  mode: string | undefined,
) => {
  return typeof mode !== 'undefined' && mode in ConnectionOnWebsocketMode
    ? ConnectionOnWebsocketMode[mode as keyof typeof ConnectionOnWebsocketMode]
    : ConnectionOnWebsocketMode.ONLY_SUBSCRIPTIONS;
})(process.env['REACT_APP_CONNECTION_ON_WEBSOCKET']);

// console.log('CONNECTION_ON_WEBSOCKET', CONNECTION_ON_WEBSOCKET);

// console.log('relayStylePagination()', relayStylePagination());

// const logPolicy = (name, rules) => {
//   return {
//     read: (existing, opt) => {
//       console.log(name, ' read ', opt);
//       const res = rules.read(existing, opt);
//       console.log(name, ' read res', res);
//       return res;
//     },
//     merge: (existing, incoming, args) => {
//       console.log(name, ' merge ', existing, incoming, args);
//       const res = rules.merge(existing, incoming, args);
//       console.log(name, ' merge res', res);
//       return res;
//     },
//   };
// };

export const createApolloClient = (
  uri: string,
  uriWs: string,
  firebaseAppAuth: firebase.auth.Auth,
  errorCallback: any,
) => {
  const typePolicies: TypedTypePolicies = {
    CourseNode: {
      fields: {
        participations: relayStylePagination(),
      },
    },
    Query: {
      fields: {
        courseParticipations: relayStylePagination([
          'user',
          'course',
          'course_Company',
        ]),
        // courseParticipations: logPolicy(
        //   'courseParticipations',
        //   relayStylePagination(['user', 'course', 'course_Company']),
        // ),
      },
    },
  };

  const cache = new InMemoryCache({
    typePolicies,
    possibleTypes: fragments.possibleTypes,
  }).restore(
    (window as any).__APOLLO_STATE__ ? (window as any).__APOLLO_STATE__ : {},
  );

  if (PERSIST_APOLLO_CACHE) {
    // let persist_storage: Storage;
    if (window && window.localStorage) {
      // persist_storage = window.localStorage;
      persistCache({
        cache,
        // storage: persist_storage as any,
        storage: new LocalStorageWrapper(window.localStorage),
      });
    }
  }

  // Setup autorization links by context
  // const asyncAuthContextLink = setContext(async () => {
  //   const token = await firebaseAppAuth.currentUser?.getIdToken();
  //   return token
  //     ? {
  //         token,
  //         headers: { Authorization: `JWT ${token}` },
  //       }
  //     : {};
  // });

  function* extractFiles(obj: any): any {
    if (
      (typeof File !== 'undefined' && obj instanceof File) ||
      (typeof Blob !== 'undefined' && obj instanceof Blob) ||
      obj instanceof ReactNativeFile
    ) {
      yield obj;
    } else if (Array.isArray(obj)) {
      for (const k of obj) {
        yield* extractFiles(k);
      }
    } else if (typeof obj === 'object' && obj !== null) {
      for (const k of Object.keys(obj)) {
        yield* extractFiles(obj[k]);
      }
    } else {
      return;
    }
  }

  // Setup default http links
  const httpLink = createUploadLink({ uri });
  // const httpLink = createHttpLink({ uri });

  // const wsClient = new SubscriptionClient( uriWs, {
  //   reconnect: true
  // });

  const wsClient = new WsSubscriptionClient(firebaseAppAuth, uriWs, {
    reconnect: true,
    reconnectionAttempts: 10,
    timeout: 60000, // 1 minutes
  });

  const wsLink = new WebSocketLink(wsClient);

  // Setup autorization  links
  const authLink = new FirebaseAuthLink(firebaseAppAuth);

  // Setup state link
  // const stateLink = withClientState({
  //   cache,
  //   resolvers: {},
  // });
  const errorLink = onError(errorCallback);

  const beLink = split(
    // split based on operation type
    (operation) => {
      const extract = extractFiles(operation.variables);
      if (extract.next().value) {
        return false;
      }

      const { query } = operation;
      switch (CONNECTION_ON_WEBSOCKET) {
        case ConnectionOnWebsocketMode.ALL:
          return true;
        case ConnectionOnWebsocketMode.NONE:
          return false;
        case ConnectionOnWebsocketMode.ONLY_SUBSCRIPTIONS:
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
      }
    },
    wsLink,
    httpLink,
  );

  const link = ApolloLink.from([
    // asyncAuthContextLink,
    // stateLink,
    authLink,
    errorLink,
    beLink,
  ]);

  // const link = ApolloLink.from([stateLink, authLink, httpLink]);

  const client = new ApolloClient({
    cache,
    link,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    assumeImmutableResults: true,
  });

  // authLink require a reverse connection with client
  authLink.setClient(client);
  return client;
};
