import Box from '@mui/material/Box';
import { ErrorResponse } from 'apollo-link-error';
import Typography from 'components/atoms/Typography';
import React from 'react';
import ReactJson from 'react-json-view';
import { toast, ToastOptions } from 'react-toastify';

interface ErrorProps {
  message: string;
  locations?: any;
  path?: any;
}

const isProd = process.env.NODE_ENV === 'production';
const Error = ({ message, locations, path }: ErrorProps) => {
  return (
    <div>
      <Typography variant={'h7'}>{message}</Typography>
      {!isProd && (
        <Box bgcolor="white">
          <ReactJson src={{ locations, path }} collapsed />
        </Box>
      )}
    </div>
  );
};

const errorLink = (error: ErrorResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { graphQLErrors, networkError, forward, operation, response } = error;
  // console.log('errorLink error', error)

  const toastErrorOpts = (
    !isProd
      ? {
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          position: 'bottom-left',
        }
      : {}
  ) as ToastOptions;

  if (graphQLErrors !== undefined) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // console.log('e:', message, locations, path)
      if (!isProd) {
        if (path && !path.includes('roomPresence')) {
          toast.error(
            <Error {...{ message, locations, path }} />,
            toastErrorOpts,
          );
        }
      } else {
        console.error(`GQL Error: ${message}`);
      }
    });
  }
  // if (networkError) toast.error(`[Network error]: ${networkError}`, toastErrorOpts);
  if (networkError) console.error(`[Network error]: ${networkError}`);
  // if (response) response.errors = undefined;

  // return forward(operation);
};

export default errorLink;
