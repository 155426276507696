import './index';
import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseAnalyticsWrapper = () => {
  const eventBuffer: Array<any> = [];
  let realAnalytics = undefined;

  const fakeAnalytics = {
    logEvent: (
      eventName: firebase.analytics.CustomEventName<any>,
      eventParams?: { [key: string]: any },
      options?: firebase.analytics.AnalyticsCallOptions,
    ) => {
      console.log(
        'BUFFER LOGGING: logEvent!!',
        eventName,
        eventParams,
        options,
      );
      eventBuffer.push({ event: 'logEvent', eventName, eventParams, options });
      // console.log('BUFFER ', eventBuffer);
    },
    setUserId: (
      id: string,
      options?: firebase.analytics.AnalyticsCallOptions,
    ) => {
      // console.log('BUFFER LOGGING: setUserId!!');
      eventBuffer.push({ event: 'setUserId', id, options });
    },
    setUserProperties: (
      properties: firebase.analytics.CustomParams,
      options?: firebase.analytics.AnalyticsCallOptions,
    ) => {
      // console.log('BUFFER LOGGING: setUserProperties!!');
      eventBuffer.push({
        event: 'setUserProperties',
        properties,
        options,
      });
    },
  };

  // let firebaseAnalytics = fakeAnalytics;

  const firebaseAnalyticsProxy = new Proxy(fakeAnalytics, {
    get: (target, prop, receiver) => {
      // console.log('log', target, prop, receiver);
      if (realAnalytics === undefined) {
        return fakeAnalytics[prop];
      } else {
        return realAnalytics[prop];
      }
      // return Reflect.get(...arguments)
    },
  });
  // console.log('LOGGING: eventBuffer', eventBuffer);

  if (typeof window !== 'undefined') {
    window.addEventListener('iubenda_preferences', (event: any) => {
      if (event.detail.purposes[4]) {
        realAnalytics = firebase.analytics();
        // firebaseAnalytics = firebase.analytics();
        // console.log('## IUBENDA ENABLED');
        // console.log('## BUFFER iubenda ', eventBuffer);

        while (eventBuffer.length > 0) {
          const e = eventBuffer.shift();
          switch (e.event) {
            case 'logEvent':
              console.log(
                'LOGGING: logEvent!!',
                e.eventName,
                e.eventParams,
                e.options,
              );
              realAnalytics.logEvent(e.eventName, e.eventParams, e.options);
              // eventBuffer.splice(idx);
              break;

            case 'setUserId':
              console.log('LOGGING: setUserId!!', e.id, e.options);
              realAnalytics.setUserId(e.id, e.options);
              // eventBuffer.splice(idx);
              break;
            case 'setUserProperties':
              console.log(
                'LOGGING: setUserProperties!!',
                e.properties,
                e.options,
              );
              realAnalytics.setUserProperties(e.properties, e.options);
              // eventBuffer.splice(idx);

              break;
            default:
              break;
          }
        }
      }
    });
  }

  return firebaseAnalyticsProxy;
};

export const firebaseAnalytics = firebaseAnalyticsWrapper();
