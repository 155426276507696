import React, { useState } from 'react';
import {
  useAuthController,
  authControllerType,
} from 'hooks/auth/useAuthController';

export enum AuthMode {
  LOGIN,
  SIGNUP,
}

export interface AuthContextType {
  authController?: authControllerType;
  modeLogin: boolean;
  modeSignup: boolean;
  forceVisibleIfNotLogged: boolean;
  signupButtonEnable: boolean;
  loginGoogleButtonEnable: boolean;
  mode: AuthMode;
  codeVerificationMode: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleVisible: () => void;
  onLogin: () => void;
  onSignup: (val?: boolean) => void;
  setForceVisibleIfNotLogged: (val: boolean) => void;
  setSignupButtonEnable: (val: boolean) => void;
  setLoginGoogleButtonEnable: (val: boolean) => void;
}

const unmountCtxWarning = () =>
  console.warn(
    'You are using a fake context. Check to access context from a child of the AuthContextProvider component',
  );

const UnmountedContext = {
  modeLogin: false,
  modeSignup: false,
  forceVisibleIfNotLogged: false,
  signupButtonEnable: true,
  loginGoogleButtonEnable: true,
  mode: AuthMode.LOGIN,
  codeVerificationMode: false,
  visible: false,
  setVisible: unmountCtxWarning,
  toggleVisible: unmountCtxWarning,
  onLogin: unmountCtxWarning,
  onSignup: unmountCtxWarning,
  setForceVisibleIfNotLogged: unmountCtxWarning,
  setSignupButtonEnable: unmountCtxWarning,
  setLoginGoogleButtonEnable: unmountCtxWarning,
};

export const AuthContext =
  React.createContext<AuthContextType>(UnmountedContext);

export const AuthContextProvider = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState(AuthMode.LOGIN);
  const [codeVerificationMode, setCodeVerificationMode] = useState(false);

  const [forceVisibleIfNotLogged, setForceVisibleIfNotLogged] = useState(false);
  const [signupButtonEnable, setSignupButtonEnable] = useState(true);
  const [loginGoogleButtonEnable, setLoginGoogleButtonEnable] = useState(true);

  const authController = useAuthController();

  const toggleVisible = () => {
    setVisible((visible) => !visible);
  };
  const onLogin = () => {
    setMode(AuthMode.LOGIN);
    setVisible(true);
  };
  const onSignup = (codeVerificationMode?: boolean) => {
    if (codeVerificationMode) {
      setCodeVerificationMode(true);
    }
    setMode(AuthMode.SIGNUP);
    setVisible(true);
  };

  const value = {
    authController,
    modeLogin: mode === AuthMode.LOGIN,
    modeSignup: mode === AuthMode.SIGNUP,
    mode,
    codeVerificationMode,
    forceVisibleIfNotLogged,
    setForceVisibleIfNotLogged,
    signupButtonEnable,
    setSignupButtonEnable,
    loginGoogleButtonEnable,
    setLoginGoogleButtonEnable,

    visible:
      visible ||
      (forceVisibleIfNotLogged &&
        !authController.user &&
        !authController.initialising &&
        !authController.fetchMeLoading),
    setVisible,
    toggleVisible,
    onLogin,
    onSignup,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
export const AuthContextConsumer = AuthContext.Consumer;
