import React from 'react';

import { router, setAppType } from '../../utils/routingManager/index';
import { applicationType } from 'apps/types';
import Url from '../../utils/routingManager/url';

export const RoutingContext = React.createContext<{
  router: Url;
}>(null as any);
export const RoutingContextConsumer = RoutingContext.Consumer;

type RoutingContextProviderProps = {
  appType: applicationType;
};

export const RoutingContextProvider: React.FC<RoutingContextProviderProps> = (
  props: any,
) => {
  const { appType } = props;

  const value = React.useMemo(() => {
    setAppType(appType);
    return {
      router,
    };
  }, [appType]);

  return (
    <RoutingContext.Provider value={value}>
      {props.children}
    </RoutingContext.Provider>
  );
};
