import { filter } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface LanguageProp {
  language: string;
  __uuid: string;
}

interface LanguageStateProps {
  languages: LanguageProp[];
}

export interface ActionProps {
  type: 'setLanguage' | 'removeLanguage';
  uuid: string;
  language: string;
}

const initialState = { languages: [] };

const reducer = (state: LanguageStateProps, action: ActionProps) => {
  switch (action.type) {
    case 'setLanguage':
      const currentLanguage = {
        language: action.language,
        __uuid: action.uuid,
      };
      const componentIdx = state.languages.findIndex(
        (l) => l.__uuid === action.uuid,
      );
      // edit the element in the list if the language of the same component is alredy in languages
      // otherwise add the language at the end of the list
      if (componentIdx !== -1) {
        const editedLanguages = state.languages.splice(
          componentIdx,
          1,
          currentLanguage,
        );
        return { ...state, languages: editedLanguages };
      } else {
        return { ...state, languages: [...state.languages, currentLanguage] };
      }
    case 'removeLanguage':
      // filters languages leaving only the elements that do not correspond to current uuid
      return {
        ...state,
        languages: filter(state.languages, (l) => l.__uuid !== action.uuid),
      };
    default:
      throw new Error();
  }
};

export const LanguageContext = React.createContext(null as any);

export const LanguageContextProvider = (props: any) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // this method allows any component to provide a default language and set it globally.
  // the langauges received by each component are added to the "languages" list
  // and the last one is used to set the current language.
  // A cleanup method is returned.
  const updateLanguages = React.useCallback(
    (uuid: string, language: string) => {
      dispatch({ type: 'setLanguage', uuid, language });
      const cleanup = () => {
        dispatch({ type: 'removeLanguage', uuid, language });
      };
      return cleanup;
    },
    [],
  );

  const lastLang = useMemo(() => {
    const lastLangReq = state.languages[state.languages.length - 1];
    return lastLangReq?.language;
  }, [state.languages]);

  React.useEffect(() => {
    if (lastLang && lastLang !== currentLanguage) {
      i18n.changeLanguage(lastLang);
    }
  }, [currentLanguage, i18n, lastLang]);

  const value = {
    updateLanguages,
    currentLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {props.children}
    </LanguageContext.Provider>
  );
};
export const LanguageContextConsumer = LanguageContext.Consumer;
