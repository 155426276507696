import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

const EmptyLayout: React.FC = (props: any) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">{props.children}</Container>
    </React.Fragment>
  );
};

export default EmptyLayout;
