import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { RESOURCES } from './resources';

i18next.use(initReactI18next).init({
  resources: RESOURCES,
  lng: 'en',
  fallbackLng: ['en', 'it'],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18next;
