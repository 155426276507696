import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Hero from './Hero';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    overlay: {
      width: '100%',
      height: '100%',
      color: '#ffffff10',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: '#ffffff66',
    },
  }),
);

const Spinner = (props: { size?: string | number }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" {...props} />
    </div>
  );
};

interface OverlaySpinnerProps {
  relative?: boolean;
  overlay?: boolean;
  spinnerProps?: { size?: string | number };
}

const OverlaySpinner: React.FC<OverlaySpinnerProps> = ({
  relative,
  overlay = false,
  spinnerProps,
}) => {
  return (
    <Hero overlay={overlay} relative={relative}>
      <Spinner {...spinnerProps} />
    </Hero>
  );
};

export { OverlaySpinner, Spinner };

export default Spinner;
