import { EventEmitter } from 'events';
import { getFirebaseTokenFromSW } from 'serviceWorker';
import { ClientOptions, SubscriptionClient } from 'subscriptions-transport-ws';

export const WsConnector = new EventEmitter();

export class WsSubscriptionClient extends SubscriptionClient {
  firebaseAppAuth: firebase.auth.Auth;
  last_sent_token: string | undefined;
  heartbeatInterval: NodeJS.Timeout | undefined;
  constructor(
    firebaseAppAuth: firebase.auth.Auth,
    url: string,
    options?: ClientOptions,
    webSocketImpl?: any,
    webSocketProtocols?: string | string[],
  ) {
    const { connectionParams: origCconnectionParams, ...otherOpt } =
      options || {};

    const getTokenFromFirebase = () =>
      new Promise((resolve, reject) => {
        firebaseAppAuth.onAuthStateChanged(user => {
          // console.log('----------RESOLVED getTokenFromFirebase --------')
          if (user) {
            user.getIdToken().then(token => {
              resolve(token);
            });
          } else {
            return resolve(null);
          }
        });
      });

    // wait firebaseAppAuth initialize for init_connection
    const connectionParams = () => {
      //Possible incompatibility with Internet Explorer https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/race
      return Promise.race([
        getFirebaseTokenFromSW(),
        getTokenFromFirebase(),
      ]).then(token => {
        // console.log('resolved token', token);
        return token
          ? {
              auth: {
                token: token,
              },
            }
          : {};
      });

      // return new Promise((resolve, reject) => {
      //   firebaseAppAuth.onAuthStateChanged(user => {
      //     if (user) {
      //       user.getIdToken().then(token => {
      //         return resolve({
      //           auth: {
      //             token: token,
      //           },
      //         });
      //       });
      //     } else {
      //       return resolve({});
      //     }
      //   });
      // });
    };

    super(url, { connectionParams, ...otherOpt }, webSocketImpl);

    this.last_sent_token = undefined;
    this.firebaseAppAuth = firebaseAppAuth;

    if (firebaseAppAuth === undefined) {
      return;
    }

    // send new token when cheange authStatus
    this.firebaseAppAuth.onAuthStateChanged(user => {
      this.sendToken();
    });
    this.firebaseAppAuth.onIdTokenChanged(user => {
      this.sendToken();
    });

    this.onConnecting(() => {
      console.info('WS on connecting');
    });

    this.onConnected(() => {
      console.info('WS on connected');
      this.sendToken();
    });

    this.onReconnecting(() => {
      console.info('WS on reconnecting');
    });

    this.onReconnected(() => {
      console.info('WS on reconnected');
      this.sendToken();
    });

    this.onDisconnected(() => {
      console.info('WS on disconnected');
      this.last_sent_token = undefined;
    });

    const addTokenMiddleware = {
      applyMiddleware: async (options: any, next: any) => {
        const token = await firebaseAppAuth.currentUser?.getIdToken();
        if (!token || token === this.last_sent_token) {
          // console.log('addTokenMiddleware skyp')
          next();
        } else {
          // console.log('addTokenMiddleware update')
          options.auth = {
            token: token,
          };
          this.last_sent_token = token;
          next();
        }
      },
    };

    this.use([addTokenMiddleware]);

    // ping server
    // send new token when cheange connecting
    this.heartbeatInterval = setInterval(() => this.sendHeartbeat(), 30000);
    this.sendHeartbeat();

    WsConnector.on('message', this._sendMessage);

    WsConnector.on('track_presence', roomName => {
      this._sendMessage({
        type: 'track_presence',
        payload: {
          roomName,
        },
      });
    });

    WsConnector.on('end_track_presence', roomName => {
      this._sendMessage({
        type: 'end_track_presence',
        payload: {
          roomName,
        },
      });
    });
  }

  private async _sendMessage(message: {
    type: string;
    payload: { [key: string]: any };
  }) {
    const serializedMessage = JSON.stringify(message);
    try {
      this.client.send(serializedMessage);
    } catch (error) {
      console.warn(error);
    }
  }

  private async sendHeartbeat() {
    const kaMessage = JSON.stringify({
      type: 'heartbeat',
    });
    try {
      this.client.send(kaMessage);
    } catch (error) {
      // console.warn(error);
    }
  }

  private async sendToken() {
    const token = await this.firebaseAppAuth.currentUser?.getIdToken();

    if (token === this.last_sent_token) {
      // skyp if token is already sent
      return;
    }
    const serializedMessage = JSON.stringify({
      type: 'update_auth_token',
      payload: {
        auth: {
          token,
        },
      },
    });

    try {
      this.client.send(serializedMessage);
      this.last_sent_token = token;
    } catch (error) {
      console.warn(error);
    }
  }
}
