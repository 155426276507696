import Box from '@mui/material/Box';
import { PageNotFound } from 'components/atoms/@GraphicSvg/UndrowExport/PageNotFound';
import Button from 'components/atoms/Button';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import EmptyLayout from '../Layouts/EmptyLayout';

interface NotFoundPageProps {
  to?: string;
  label?: string;
  external?: boolean;
}

const NotFoundPage: React.FC<NotFoundPageProps> = (props: any) => {
  const { to = '/', label = 'Go to Home', external = false } = props;

  return (
    <EmptyLayout>
      <Helmet>
        <title>Not Found Page</title>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <Box display="flex" alignItems="center" flexDirection="column">
        <PageNotFound
          color="primary"
          style={{
            fontSize: 400,
          }}
        />

        <Button
          variant="outlined"
          color="primary"
          {...(external ? { href: to } : { component: Link, to })}
        >
          {label}
        </Button>
      </Box>
    </EmptyLayout>
  );
};

export default NotFoundPage;
