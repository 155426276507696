import { Container, Box, Typography, Button } from '@mui/material';
import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false as boolean };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    if (error.name === 'ChunkLoadError') {
      return { hasError: true, error };
    }
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log('Error Boundary Caught:', error, errorInfo);
  }
  reload = () => {
    window.location.reload();
  };
  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="sm" sx={{ height: '100vh' }}>
          <Box
            display="flex"
            height={'100%'}
            flexDirection="column"
            justifyContent={'center'}
            alignItems="center"
          >
            <Typography align="center" variant="h5">
              {
                'The platform has been updated, please refresh your browser to see the latest content.'
              }
            </Typography>
            <Button
              sx={{ marginTop: 5 }}
              variant="outlined"
              onClick={this.reload}
            >
              {'Refresh'}
            </Button>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}
