import {
  useLogInMutation,
  useLogOutMutation,
  useMeLazyQuery,
} from 'generated/graphql';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { firebaseAnalytics } from 'services/firebase/analytics';
import { useTranslation } from 'react-i18next';
import {
  firebaseAppAuth,
  providers,
  useFirebasePersistence,
  firebase,
} from '../../services/firebase';

export const useAuthController = () => {
  const [firebaseUser, initialising] = useAuthState(firebaseAppAuth);
  const { persistence } = useFirebasePersistence();
  const [signInProgress, setSignInProgress] = React.useState(false);
  const [signOutProgress, setSignOutProgress] = React.useState(false);
  const { t } = useTranslation();
  // gql hooks
  const [logIn] = useLogInMutation({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    refetchQueries: ['Me'],
  });

  const [logOut] = useLogOutMutation({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    refetchQueries: ['Me'],
  });

  const [
    fetchMe,
    { data: dataMe, loading: fetchMeLoading, refetch: refetchMe },
  ] = useMeLazyQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  React.useEffect(() => {
    if (firebaseUser) {
      fetchMe();
    }
  }, [fetchMe, firebaseUser]);

  React.useEffect(() => {
    const onAuthStateChangedListener = firebaseAppAuth.onAuthStateChanged(
      (user) => {
        // console.log('AUTH onAuthStateChanged', user);
        fetchMe();
      },
    );

    const onIdTokenChangedListener = firebaseAppAuth.onIdTokenChanged(
      (user) => {
        // console.log('AUTH onIdTokenChanged', user);
        fetchMe();
      },
    );

    return () => {
      onAuthStateChangedListener();
      onIdTokenChangedListener();
    };
  }, [fetchMe]);

  const setupPersistence = React.useCallback(
    async (rememberMe: boolean = true) => {
      if (rememberMe) {
        await persistence.setLocalPersistence();
      } else {
        await persistence.setSessionPersistence();
      }
    },
    [persistence],
  );

  const getAnoymousUserIdToken = React.useCallback(async () => {
    // console.log(
    //   'getAnoymousUserIdToken',
    //   firebaseUser,
    //   firebaseUser?.isAnonymous,
    // );
    return firebaseUser && firebaseUser.isAnonymous
      ? await firebaseUser.getIdToken()
      : undefined;
  }, [firebaseUser]);

  const _signInWithEmailLink = React.useCallback(
    async (email: string, emailLink: string) => {
      const isEmailLink = firebaseAppAuth.isSignInWithEmailLink(emailLink);

      console.log('signInWithEmailLink isEmailLink', isEmailLink);
      if (!isEmailLink) {
        return {
          success: false,
          error: { message: 'Invalid SignInWithEmailLink' },
        };
      }

      let credential;
      try {
        credential = await firebaseAppAuth.signInWithEmailLink(
          email,
          emailLink,
        );
      } catch (error) {
        return { success: false, error };
      }

      if (!credential.user) {
        throw new Error('Invalid EmailAndPassword credential user');
      }
      if (!credential.additionalUserInfo) {
        throw new Error(
          'Invalid EmailAndPassword credential additionalUserInfo',
        );
      }
      const tokenID = await credential.user.getIdToken();
      const input = {
        idToken: tokenID,
        isNewUser: credential.additionalUserInfo.isNewUser,
        providerId: 'password',
        currentUser: JSON.stringify({
          displayName: credential.user.displayName,
        }),
        redirectUrl: window.location.href,
      };

      try {
        await logIn({ variables: { input } });
      } catch (error) {
        const e = error.graphQLErrors[0] as any;
        if (e.code === 'email-not-verified') {
          return {
            success: true,
            requiredEmailVerification: true,
            errors: [],
          };
        }
      }

      firebaseAnalytics.logEvent('login', { method: 'email_link' });
      // refetchMe();
      return { success: true, requiredEmailVerification: false, errors: [] };

      // // firebaseAppAuth.signInWithEmailLink
      // console.log('signInWithEmailLink',email, emailLink)
      // firebaseAppAuth.signInWithEmailLink(email, emailLink)
      // .then((res) => {
      //   console.log('signInWithEmailLink res:',res)
      // })
      // .catch((error) => {
      //   // Some error occurred, you can inspect the code: error.code
      //   // Common errors could be invalid email and invalid or expired OTPs.
      //   console.log('signInWithEmailLink error:',error)

      //   if (error.code === 'auth/invalid-action-code') {
      //     // The action code is invalid. This can happen if the code is malformed, expired, or has already been used.
      //     console.log('signInWithEmailLink error:',error.message)
      //   }
      // });
    },
    [logIn],
  );

  const signInWithEmailLink = React.useCallback(
    async (email: string, emailLink: string) => {
      setSignInProgress(true);
      return _signInWithEmailLink(email, emailLink).finally(() =>
        setSignInProgress(false),
      );
    },
    [_signInWithEmailLink],
  );

  const signOut = React.useCallback(() => {
    if (signOutProgress) {
      return;
    }
    setSignOutProgress(true);
    return firebaseAppAuth.signOut().then(() => {
      logOut().then(() => {
        setSignOutProgress(false);
        firebaseAnalytics.logEvent('sign_out');
      });
    });
  }, [logOut, signOutProgress]);

  const signInAnonymously = React.useCallback(
    async (rememberMe: boolean = true) => {
      await setupPersistence(rememberMe);

      setSignInProgress(true);
      await firebaseAppAuth.signInAnonymously();
      setSignInProgress(false);
      firebaseAnalytics.logEvent('sign_up', { method: 'signInAnonymously' });
    },
    [setupPersistence],
  );

  const signInWithGoogle = React.useCallback(
    async (rememberMe: boolean = true) => {
      const anoymousUserIdToken = await getAnoymousUserIdToken();
      console.log('anoymousUserIdToken', anoymousUserIdToken);
      await setupPersistence(rememberMe);

      const credential = await firebaseAppAuth.signInWithPopup(
        providers['googleProvider'],
      );

      if (credential === undefined) {
        throw new Error('Invalid google credential');
      }

      if (!credential.user) {
        throw new Error('Invalid google credential user');
      }

      if (!credential.additionalUserInfo) {
        throw new Error('Invalid google credential additionalUserInfo');
      }

      const { isNewUser, providerId } = credential.additionalUserInfo;

      const tokenID = await credential.user.getIdToken();
      const input = {
        anoymousUserIdToken,
        idToken: tokenID,
        isNewUser,
        providerId,
        currentUser: JSON.stringify({
          displayName: credential.user.displayName,
        }),
        profile: JSON.stringify(credential.additionalUserInfo.profile),
      };
      await logIn({ variables: { input } }).catch((err: any) =>
        console.error('logIn err', err),
      );

      if (isNewUser) {
        firebaseAnalytics.logEvent('sign_up', { method: 'social', providerId });
      } else {
        firebaseAnalytics.logEvent('login', { method: 'social', providerId });
      }
      // await refetchMe();
    },
    [getAnoymousUserIdToken, logIn, setupPersistence],
  );
  const signInWithEmailAndPassword = React.useCallback(
    async (email: string, password: string, rememberMe: boolean = true) => {
      const anoymousUserIdToken = await getAnoymousUserIdToken();
      await setupPersistence(rememberMe);

      let credential;
      try {
        credential = await firebaseAppAuth.signInWithEmailAndPassword(
          email,
          password,
        );
      } catch (error) {
        const errors = [];
        if (error.code === 'auth/user-not-found') {
          errors.push({
            name: 'email',
            code: error.code,
            message: error.message,
          });
        } else if (error.code === 'auth/wrong-password') {
          errors.push({
            name: 'password',
            code: error.code,
            message: error.message,
          });
        }
        return { success: false, errors };
      }

      if (!credential.user) {
        throw new Error('Invalid EmailAndPassword credential user');
      }
      if (!credential.additionalUserInfo) {
        throw new Error(
          'Invalid EmailAndPassword credential additionalUserInfo',
        );
      }
      const tokenID = await credential.user.getIdToken();
      const input = {
        anoymousUserIdToken,
        idToken: tokenID,
        isNewUser: credential.additionalUserInfo.isNewUser,
        providerId: 'password',
        currentUser: JSON.stringify({
          displayName: credential.user.displayName,
        }),
        redirectUrl: window.location.href,
      };
      const loginResults = await logIn({ variables: { input } });
      if (loginResults.errors?.length) {
        const e = loginResults.errors[0] as any;
        if (e.code === 'email-not-verified') {
          toast.success(t('auth.succesfullyRegistered'), { autoClose: false });
          return {
            success: true,
            requiredEmailVerification: true,
            errors: [],
          };
        }
      }
      // try {
      //   await logIn({ variables: { input } });
      // } catch (error) {
      //   const e = error.graphQLErrors[0] as any;
      //   if (e.code === 'email-not-verified') {
      //     emailVerificationToast();
      //     return {
      //       success: true,
      //       requiredEmailVerification: true,
      //       errors: [],
      //     };
      //   }
      // }

      firebaseAnalytics.logEvent('login', { method: 'email_and_password' });
      // refetchMe();
      return { success: true, requiredEmailVerification: false, errors: [] };
    },
    [getAnoymousUserIdToken, logIn, setupPersistence, t],
  );

  const createUserWithEmailAndPassword = React.useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      rememberMe: boolean = true,
      codeVerificationMode: boolean = false,
    ) => {
      const anoymousUserIdToken = await getAnoymousUserIdToken();
      await setupPersistence(rememberMe);

      let credential;
      try {
        credential = await firebaseAppAuth.createUserWithEmailAndPassword(
          email,
          password,
        );
        console.log('credential', credential);
      } catch (error: any) {
        const errors: any[] = [];
        console.log('error', error);
        if (error.code === 'auth/email-already-in-use') {
          errors.push({
            name: 'email',
            code: error.code,
            message: error.message,
          });
        } else if (error.code === 'auth/wrong-password') {
          errors.push({
            name: 'password',
            code: error.code,
            message: error.message,
          });
        } else {
          errors.push({
            name: 'password',
            code: error.code,
            message: error.message,
          });
        }
        return { user: null, errors, success: false };
      }

      if (credential === undefined) {
        throw new Error('Invalid signup credential');
      }

      if (!credential.user) {
        throw new Error('Invalid signup credential user');
      }

      if (!credential.additionalUserInfo) {
        throw new Error('Invalid signup credential additionalUserInfo');
      }

      const tokenID = await credential.user.getIdToken();
      const input = {
        anoymousUserIdToken,
        idToken: tokenID,
        isNewUser: credential.additionalUserInfo.isNewUser,
        providerId: credential.additionalUserInfo.providerId,
        currentUser: JSON.stringify({
          firstName,
          lastName,
          displayName: `${firstName} ${lastName}`,
        }),
        redirectUrl: window.location.href,
        codeVerificationMode: codeVerificationMode,
      };
      const loginResults = await logIn({ variables: { input } });
      if (loginResults.errors?.length) {
        const e = loginResults.errors[0] as any;
        if (e.code === 'email-not-verified') {
          toast.success(t('auth.succesfullyRegistered'), { autoClose: false });
          return {
            success: true,
            requiredEmailVerification: true,
            errors: [],
          };
        }
      }
      // try {
      //   await logIn({ variables: { input } });
      // } catch (error) {
      //   const e = error.graphQLErrors[0] as any;
      //   if (e.code === 'email-not-verified') {
      //     emailVerificationToast();
      //     return {
      //       success: true,
      //       requiredEmailVerification: true,
      //       errors: [],
      //     };
      //   }
      // }

      firebaseAnalytics.logEvent('sign_up', { method: 'email_and_password' });
      // refetchMe();
      return {
        success: true,
        errors: [],
      };
    },
    [getAnoymousUserIdToken, logIn, setupPersistence, t],
  );

  const reauthenticateWithGoogle = React.useCallback(async () => {
    if (!firebaseUser) {
      throw new Error('current user not found');
    }

    const credential = await firebaseUser.reauthenticateWithPopup(
      providers['googleProvider'],
    );
    return credential;
  }, [firebaseUser]);

  const reauthenticateWithPassword = React.useCallback(
    async (password) => {
      if (!firebaseUser) {
        throw new Error('current user not found');
      }

      if (!firebaseUser?.email) {
        throw new Error('current user has not email');
      }

      var credentials = firebase.auth.EmailAuthProvider.credential(
        firebaseUser.email,
        password,
      );

      return firebaseUser.reauthenticateWithCredential(credentials);
    },
    [firebaseUser],
  );

  const user = dataMe?.me;

  React.useEffect(() => {
    if (user?.id) {
      firebaseAnalytics.setUserId(user.id);
      firebaseAnalytics.setUserProperties({
        is_anonymous: user.isAnonymous,
        is_staff: user.isStaff,
        is_superuser: user.isSuperuser,
        email: user.email,
      });
    }
  }, [user]);

  return {
    user,
    initialising,
    firebaseUser,
    signOut,
    signInAnonymously,
    signInWithGoogle,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    createUserWithEmailAndPassword,
    reauthenticateWithGoogle,
    reauthenticateWithPassword,
    firebaseAppAuth,
    signInProgress,
    signOutProgress,
    fetchMeLoading,
    refetchMe,
    providers,
    // firebaseApp
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useAuthControllerFake = (false as true) && useAuthController(); //get an "instance" of the function

export type authControllerType = typeof useAuthControllerFake;

export default useAuthController;
