// import 'react-app-polyfill/ie11';
import FIREBASE from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/analytics';
import firebaseConfig from './firebaseConfig';

export const firebase = FIREBASE;
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAppAuth = firebaseApp.auth();
export const firebaseDb = firebaseApp.database();
// export const firebaseAnalytics = firebase.analytics();

export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
  emailProvider: new firebase.auth.EmailAuthProvider(),
};

export function useFirebasePersistence() {
  // Rememeber me is true
  const setLocalPersistence = () =>
    firebaseAppAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

  // Rememeber me is false
  const setSessionPersistence = () =>
    firebaseAppAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

  return {
    persistence: {
      setLocalPersistence,
      setSessionPersistence,
    },
  };
}
