import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
  auth: process.env.REACT_APP_GTM_AUTH as string,
  preview: process.env.REACT_APP_GTM_PREVIEW as string,
};

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENVIRONMENT
) {
  TagManager.initialize(tagManagerArgs);
}
