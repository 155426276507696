import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
  width?: string | number;
  height?: string | number;
  overlay?: boolean;
  relative?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    root: {
      width: (props) => props.width,
      height: (props) => props.height,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    overlay: {
      // color: '#ffffff10',
      backgroundColor: theme.palette.background.default,
    },
    relative: {
      position: 'relative',
    },
  }),
);

const Hero: React.FC<Props> = (props) => {
  const { overlay, className, children, relative } = props;

  const classes = useStyles(props);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={clsx(
        classes.root,
        {
          [classes.overlay]: overlay,
          [classes.relative]: relative,
        },
        className,
      )}
    >
      <Box>{children}</Box>
    </Box>
  );
};

Hero.defaultProps = {
  width: '100%',
  height: '100%',
};

export default Hero;
export { Hero };
