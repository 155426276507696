import { rootUrl as defaultRouter } from './urlsconf/default_conf';
import { rootUrl as companyRouter } from './urlsconf/company_conf';
import { rootUrl as courseRouter } from './urlsconf/course_conf';
import { applicationType } from 'apps/types';
import Url from './url';

const router = new Url(``, '');

// todo: Code-splitting ===>> asyn import of specific conf (use import)
const setAppType = (appType: applicationType) => {
  // console.log('router.setAppType', appType);
  switch (appType) {
    case applicationType.company:
      router.setRoot(companyRouter);
      break;
    case applicationType.course:
      router.setRoot(courseRouter);
      break;
    case applicationType.common:
    default:
      router.setRoot(defaultRouter);
  }
};

export default router;
export { router, setAppType };
