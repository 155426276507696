import { createApolloClient } from './config';
import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/client';
// import { ErrorResponse } from 'apollo-link-error';
import { ErrorResponse } from '@apollo/client/link/error';

interface PropsApollo {
  uri: string;
  uriWs: string;
  firebaseAppAuth: any;
  children: React.ReactNode;
  errorCallback: (error: ErrorResponse) => void;
}

export class ApolloCoreProvider extends Component<PropsApollo> {
  render() {
    return (
      <ApolloProvider
        client={createApolloClient(
          this.props.uri,
          this.props.uriWs,
          this.props.firebaseAppAuth,
          this.props.errorCallback,
        )}
      >
        {this.props.children}
      </ApolloProvider>
    );
  }
}
