// import { ApolloLink } from 'apollo-link';
import { ApolloLink } from '@apollo/client';
import { MeDocument } from 'generated/graphql';

export class FirebaseAuthLink extends ApolloLink {
  token: string | null;
  firebaseAppAuth: firebase.auth.Auth;
  client: any;
  refreshTokenInterval: NodeJS.Timeout | undefined;

  constructor(firebaseAppAuth: any) {
    super();
    this.token = null;
    this.firebaseAppAuth = firebaseAppAuth;
    this.refreshTokenInterval = undefined;

    if (firebaseAppAuth === undefined) {
      // console.warn('FirebaseAuthLink: firebaseAppAuth not defined probably in mobile env');
      return;
    }
    this.firebaseAppAuth.onAuthStateChanged(user => {
      // console.log('FirebaseAuthLink.onAuthStateChanged',user);
      if (user) {
        // console.log('FirebaseAuthLink.onAuthStateChanged', user.uid, ' is_anonymous:', user.isAnonymous);
        // console.log('FirebaseAuthLink.onAuthStateChanged  -- with user');
        user.getIdToken().then(token => {
          // console.log('FirebaseAuthLink.onAuthStateChanged token', token.substring(0, 12), '..');
          this.token = token;
          this.updateQuery();
        });

        this.refreshTokenInterval = setInterval(() => {
          user.getIdToken(true).then(token => {
            // console.log('FirebaseAuthLink.onAuthStateChanged token', token.substring(0, 12), '..');
            // console.log('FirebaseAuthLink.onAuthStateChanged token', token, '..');
            this.token = token;
            this.updateQuery();
          });
        }, 600000); //update token every 10 minutes
      } else {
        if (this.refreshTokenInterval) {
          clearInterval(this.refreshTokenInterval);
        }
        // console.log('FirebaseAuthLink.onAuthStateChanged  -- MISSING user');
        this.token = null;
        this.updateQuery();
      }
    });
  }

  setClient(client: any) {
    this.client = client;
  }

  updateQuery() {
    if (!(this && this.client)) return;
    // console.log('updateQuery', this.token);
    // After login and logout (onAuthStateChanged)  some queries
    // must be updated or invalidated.
    // For now I will just destroy the whole client store or update the ME query
    // this.client.resetStore().then((resp) => {
    //     console.log('updateQuery    reset', resp)
    //     this.client.query({query: ME,fetchPolicy: 'network-only'}).then(()=>console.log('query'))
    // })
    this.client
      .query({
        query: MeDocument,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .catch((err: any) => null);
    // this.client.query({ query: ME, fetchPolicy: 'cache-and-network', errorPolicy: 'all' });
  }

  request(operation: any, forward: any) {
    const headers = this.token ? { Authorization: `JWT ${this.token}` } : {};

    operation.setContext({
      headers,
    });
    return forward(operation);
  }
}
