import makeStyles from '@mui/styles/makeStyles';
import { Variant } from '@mui/material/styles';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import { DistributiveOmit } from '@mui/types';
import clsx from 'clsx';
import React from 'react';

export type TypographyColors =
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error'
  | 'white';

interface Props extends DistributiveOmit<TypographyProps, 'variant' | 'color'> {
  component?: React.ElementType;
  bold?: boolean;
  uppercase?: boolean;
  color?: TypographyColors;
  variant?:
    | Variant
    | 'srOnly'
    | 'body3'
    | 'button1'
    | 'button2'
    | 'h7'
    | 'body4'
    | 'h4Light'
    | 'h3Light'
    | 'h7Light'
    | 'inherit';
}

const useStyles = makeStyles((theme: { [key: string]: any }) => ({
  body3: theme.typography.body3,
  body4: theme.typography.body4,
  button1: theme.typography.button1,
  button2: theme.typography.button2,
  h7: theme.typography.h7,
  h4Light: theme.typography.h4Light,
  h7Light: theme.typography.h7Light,
  h3Light: theme.typography.h3Light,
  bold: {
    fontWeight: 'bold',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  colorWhite: {
    color: theme.palette.common.white,
  },
}));

export const Typography: React.FC<Props> = (props: Props) => {
  const {
    className,
    variant = 'body1',
    color,
    bold,
    uppercase,
    ...other
  } = props;
  const classes: any = useStyles();
  const muiVariant =
    [
      'body3',
      'button1',
      'button2',
      'h7',
      'h7Light',
      'h3Light',
      'body4',
      'h4Light',
    ].indexOf(variant) >= 0
      ? 'inherit'
      : variant;

  const manageColor = ['white'].indexOf(color as TypographyColors) >= 0;
  const muiColor = manageColor
    ? ('inherit' as TypographyProps['color'])
    : color;

  return (
    <MuiTypography
      className={clsx(
        classes.root,
        {
          [classes[variant]]: variant !== 'inherit',
          [classes['bold']]: bold,
          [classes['uppercase']]: uppercase,
          [classes['colorWhite']]: color === 'white',
        },
        className,
      )}
      variant={muiVariant as Variant}
      color={muiColor as TypographyProps['color']}
      {...other}
    >
      {props.children}
    </MuiTypography>
  );
};

export default Typography;
