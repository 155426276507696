import {
  CourseNode,
  Maybe,
  MosaicoNode,
  SiteCurrentQuery,
} from 'generated/graphql';
import React from 'react';
import { NonNullable } from 'utils/typescriptUtils/NonNullable';
import { applicationType } from '../../apps/types';

type CurrentSiteCompanyType = NonNullable<
  NonNullable<SiteCurrentQuery['siteCurrent']>['company']
>;
type CurrentSiteCourseType = NonNullable<
  NonNullable<SiteCurrentQuery['siteCurrent']>['course']
>;
type CurrentSiteCourseCompanyType = NonNullable<
  CurrentSiteCourseType['company']
>;
type CurrentSiteMosaicoType = NonNullable<
  NonNullable<SiteCurrentQuery['siteCurrent']>['mosaico']
>;

// export type ImageFiledThumbnail = { __typename?: 'ImageFiledType' } & Pick<
//   ImageFiledType,
//   'name'
// > & {
//     thumbnail: Maybe<
//       { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
//     >;
//   };

export type ContextCompanyNode = Maybe<
  CurrentSiteCompanyType | CurrentSiteCourseCompanyType
>;

type AppContextType = {
  appType?: applicationType;
  isCourseApp: boolean;
  isCommonApp: boolean;
  isMosaicoApp: boolean;
  company?: ContextCompanyNode;
  // company?: Maybe<Pick<ContextCompanyNode, "name" | "id" | "slug" | "websiteUrl" | "customerName" | "phone" | "enableAuth" | "enableLogoLink">>;
  course?: Maybe<CurrentSiteCourseType>;
  mosaico?: Maybe<CurrentSiteMosaicoType>;
};

export const AppTypeEnum = applicationType;
export const AppContext = React.createContext<AppContextType>({
  isCourseApp: false,
  isCommonApp: false,
  isMosaicoApp: false,
});
export const AppContextConsumer = AppContext.Consumer;

type AppContextProviderProps = {
  appType: applicationType;
  company?: Maybe<ContextCompanyNode>;
  course?: Maybe<
    Pick<CourseNode, 'id' | 'slug' | 'layoutType' | 'navigationType' | 'demo'>
  >;
  mosaico?: Maybe<Pick<MosaicoNode, 'id'>>;
};

export const AppContextProvider: React.FC<AppContextProviderProps> = (
  props,
) => {
  const { appType, company, course, mosaico } = props;
  const value = React.useMemo(() => {
    return {
      appType,
      isCourseApp: appType === applicationType.course,
      isCompanyApp: appType === applicationType.company,
      isCommonApp: appType === applicationType.common,
      isMosaicoApp: appType === applicationType.mosaico,
      company,
      course,
      mosaico,
    };
  }, [appType, company, course, mosaico]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
