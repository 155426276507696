import T_EN from './translations/en/common.json';
import T_IT from './translations/it/common.json';
// the translations
export const RESOURCES = {
  en: {
    translation: T_EN,
  },
  it: {
    translation: T_IT,
  },
};
