import loadable from '@loadable/component';
import { OverlaySpinner } from 'components/atoms/Spinner';
import { LanguageContextProvider } from 'components/contexts/LanguageContext';
import { RoutingContextProvider } from 'components/contexts/RoutingContext';
import NotFoundPage from 'components/pages/NotFoundPage';
import { useSiteCurrentQuery } from 'generated/graphql';
import React from 'react';
import { Helmet, HelmetTags } from 'react-helmet';
import { firebaseAnalytics } from 'services/firebase/analytics';
import { AppContextProvider } from '../components/contexts/AppContext';
import { applicationType } from './types';
import { usePWAInstall } from 'react-use-pwa-install';
// import CourseAPP from './CourseApp';
// import CompanyApp from './CompanyApp';
// import CommonApp from './CommonApp';

const AsyncApp = loadable(
  (props: { appType: applicationType; landing?: string }) =>
    import(`./${props.appType}`),
  {
    fallback: <OverlaySpinner />,
  },
);

// AsyncApp.preload({appType:applicationType.course})

export const AppManagerProvider: React.FC = (props) => {
  const { data, loading } = useSiteCurrentQuery({
    errorPolicy: 'all',
  });

  const site = data?.siteCurrent;

  const appType = React.useMemo(() => {
    if (loading) return;
    if (!site) return null;
    if (site?.company) return applicationType.company;
    if (site?.course) return applicationType.course;
    if (site?.mosaico) return applicationType.mosaico;
    if (site) return applicationType.common;
  }, [loading, site]);

  const course = site?.course;
  const mosaico = site?.mosaico;
  const company = site?.company || course?.company;
  // const theme = company?.theme || course?.company?.theme;
  const landing = process.env['REACT_APP_LANDING_PAGE'] || 'Palco';

  // const defaultThemeId = company?.defaultTheme?.id;

  const onHelmetUpdate = React.useCallback(
    (newState: any, addedTags: HelmetTags, removedTags: HelmetTags) => {
      firebaseAnalytics.logEvent('page_view', {});
      // // tutti i meta con il nome che comincia con ga_ sono degli argomenti extra da inviare in google analytics
      // // note: ga_page override page path from location.pathname
      // const metaGaArgs = newState.metaTags.reduce((args, t) => {
      //   if (t.name && t.name.startsWith('ga_') && t.content) {
      //     return {
      //       [t.name.replace('ga_', '')]: t.content,
      //       ...args
      //     };
      //   }
      //   return args;
      // }, {});
    },
    [],
  );

  // INSTALL PWA
  const [showInstall, setShowInstall] = React.useState(false);
  const installPWA = usePWAInstall();

  const capture = React.useCallback(() => {
    if (!showInstall && installPWA) {
      installPWA();
      setShowInstall(true);
    }
  }, [installPWA, showInstall]);

  if (loading || appType === undefined) {
    return <OverlaySpinner />;
  }

  if (appType === null) {
    return <NotFoundPage />;
  }

  // console.log('site', site);
  // console.log('appType', appType);
  // console.log('AsyncApp', AsyncApp);
  return (
    <>
      <Helmet onChangeClientState={onHelmetUpdate}>
        <title>{company?.name}</title>

        {site?.faviconData?.htmlTags?.map((t, idx) => {
          const TagName = t?.tag as any;
          const tagProps = t?.attributes?.reduce((acc, item) => {
            if (item?.key && item.value) {
              return {
                [item?.key]: item?.value,
                ...acc,
              };
            } else {
              return acc;
            }
          }, {});

          return <TagName key={idx} {...tagProps} />;
        })}
      </Helmet>

      <LanguageContextProvider>
        {/* <React.Suspense fallback={<OverlaySpinner />}> */}
        <RoutingContextProvider appType={appType}>
          <AppContextProvider
            appType={appType}
            company={company}
            course={course}
            mosaico={mosaico}
          >
            {/* <CourseAPP></CourseAPP> */}
            <div onClickCapture={capture}>
              <AsyncApp appType={appType} landing={landing} />
            </div>
          </AppContextProvider>
        </RoutingContextProvider>
        {/* </React.Suspense> */}
      </LanguageContextProvider>
    </>
  );
};

export default AppManagerProvider;
