import { useTheme } from '@mui/material/styles';
import { GraphicSVGProps } from './types';

export const useGraphicSvgProps = (props: GraphicSVGProps) => {
  const { primaryColor, secondaryColor, ...other } = props;
  const theme = useTheme();

  const primary = primaryColor || theme.palette.primary.main;
  const secondary = secondaryColor || theme.palette.secondary.main;

  return { primary, secondary, ...other };
};
