import Url from '../url';
import {
  // adminUrls,
  makeProfileUrls,
  makeAccountUrls,
  authUrls,
  makeCourseDashboardDetailUrls,
} from './shared_conf';

const surveysUrls: any[] = [
  // new Url('questionnaire', 'survey-questionnaire', [], {
  //   it: 'questionario',
  // }),
  new Url(':id/new-response', 'survey-new-response', []),
  // new Url('result', 'surveys-result', [], { it: 'risultato' }),
];
const courseUrls: any[] = [
  new Url('', 'course-list'),
  new Url(':slug', 'course-overview'),
  new Url(':slug/_finish', 'course-finish'),
  new Url(':slug/:module_slug', 'course-module'),
];

const courseAdminDetailsUrls: any[] = [
  // new Url('', 'course-list'),
  new Url('modules/:id', 'admin-course-detail-module-detail'),
  new Url('modules', 'admin-course-detail-modules'),
  new Url('admission/:id', 'admin-course-detail-admission-user-detail'),
  new Url('admission', 'admin-course-detail-admission'),
  new Url('invitations', 'admin-course-detail-invitation'),
  new Url('dev', 'admin-course-detail-dev'),
];
const courseAdminUrls: any[] = [
  // new Url('', 'course-list'),
  new Url(':courseId', 'admin-course-detail', courseAdminDetailsUrls),
];

export const courseDashboardUrls: Url[] = [
  new Url(
    ':courseId',
    'dashboard-course-detail',
    makeCourseDashboardDetailUrls(),
  ),
];

const rootUrls: Url[] = [
  new Url('', 'home'),
  new Url('emergency-chat', 'emergency-chat'),
  new Url('surveys', 'surveys', surveysUrls),
  new Url('__auth__', 'auth', authUrls),
  new Url('profile', 'profile', makeProfileUrls()),
  new Url('account', 'account', makeAccountUrls()),
  new Url('course', 'course', courseUrls),
  new Url('admin/course', 'admin-course', courseAdminUrls),
  new Url('dashboard/course', 'dashboard-course', courseDashboardUrls),
  // ...adminUrls,
];

// const routerLocalized: Url = new Url(`/:lang(${langsRegex})`, 'locale', rootUrls);
const rootUrl: Url = new Url(``, 'locale', rootUrls);

export default rootUrl;
export { rootUrl };
