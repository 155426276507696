import pathToRegexp, { Key } from 'path-to-regexp';
import { LOCALES_AVAILABLE } from '../localizations/constants';

export default class Url {
  parent?: Url;
  store?: any;

  loacalesAvailable: string[];
  path: string;
  urlconfName: string;
  children: Url[];
  populated: boolean;
  translationMap: any;
  reverseDict: any;

  constructor(
    path: string,
    urlconfName: string,
    children: Url[] = [],
    localeMap = {},
  ) {
    this.loacalesAvailable = LOCALES_AVAILABLE;
    this.path = path;
    this.urlconfName = urlconfName;
    this.children = children;
    this.translationMap = localeMap;
    this.populated = false;
    this.reverseDict = {};
    // this.populate();
    this.connectChildrens();
    // this.parent = null;
    // this.store = null;
  }

  setRoot(root: Url) {
    // this.store = root.store
    this.loacalesAvailable = root.loacalesAvailable;
    this.path = root.path;
    this.urlconfName = root.urlconfName;
    this.children = root.children;
    this.translationMap = root.translationMap;
    this.populated = root.populated;
    this.children = root.children;
  }

  setStore(store: any) {
    this.store = store;
  }

  setParent(parent: Url) {
    this.parent = parent;
    // this.populate();
    return this;
  }

  connectChildrens() {
    this.children = this.children.map(url => {
      return url.setParent(this);
    });
  }

  populate() {
    if (this.populated) {
      throw new Error('Url is already populated');
    }

    this.loacalesAvailable.forEach(locale => {
      // console.log(locale)
      this.reverseDict[locale] = {
        [this.urlconfName]: this.localizedPath(locale),
      };

      this.children.forEach(child => {
        this.reverseDict[locale] = {
          ...this.reverseDict[locale],
          ...child.reverseDict[locale],
          // [child.urlconfName]: child.localizedPath(locale),
        };
      });
    });

    // console.log('Populated url:', this.reverseDict);
    this.populated = true;
  }

  translatePath(locale?: string) {
    if (locale && locale in this.translationMap) {
      return this.translationMap[locale];
    }
    return this.path;
  }

  localizedPath(locale?: string): string {
    let path;
    if (this.parent) {
      path = `${this.parent.localizedPath(locale)}/${this.translatePath(
        locale,
      )}`;
    } else {
      path = `${this.translatePath(locale)}`;
    }
    return path;
  }

  reverse(name: string, args: any = {}, locale?: string) {
    // name: urlconfName
    // args = {id:2}'
    // locale = localization values
    // return /foo/2

    if (!Object.prototype.hasOwnProperty.call(args, 'lang')) {
      args = {
        // eslint-disable-line
        ...args,
        lang: locale,
      };
    }
    locale = args.lang; // eslint-disable-line
    // console.log('reverse args.lang:', args.lang);

    const path = this.makePath(name, locale);
    // console.log('rpath:', path);

    const toPath = pathToRegexp.compile(path) as (
      params?: { ns?: string },
      options?: { encode: (value: string, token: string) => string },
    ) => string;

    const res = toPath(args, { encode: (value, token) => value });
    // console.log('res:,', res);
    return res;
  }

  matchUrl(url: string, locale?: string) {
    const locales = locale ? [locale] : this.loacalesAvailable;

    for (const l of locales) {
      const keys: Key[] = [];
      const path = this.localizedPath(l);
      const regex = pathToRegexp(path, keys);
      const matchObj = regex.exec(url);
      if (matchObj) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [r, ...values] = matchObj;
        let args = {};
        for (let i = 0; i < values.length; i += 1) {
          args = { [keys[i].name]: values[i], ...args };
        }
        // console.log({url:url, path:path, args:args, locale:l})
        return {
          url,
          name: this.urlconfName,
          path,
          args,
          locale: l,
        };
      }
    }
    return null;
  }

  resolve(url: string) {
    // url: /it/dashboard/enel
    // return: {lang: 'it', company_slug: 'enel'}

    function* match(n: Url): any {
      const matchObj = n.matchUrl(url);

      if (matchObj) {
        yield matchObj;
      } else {
        for (const c of n.children) {
          yield* match(c);
        }
      }
    }

    const res = [...match(this)];
    if (!res.length) {
      throw new Error(`Route not found for resolve:${url}`);
    }
    return res[0];
  }

  makePath(name: string, lang?: string) {
    const locale = lang;
    function* match(n: Url): any {
      if (n.urlconfName === name) {
        yield n.localizedPath(locale);
      } else {
        for (const c of n.children) {
          yield* match(c);
        }
      }
    }

    // const res = [...match(this)]; //not workinj in node.js
    const res = Array.from(match(this));
    if (!res.length) {
      throw new Error(`Route not found for makePath:${name}`);
    }
    return res[0] as string;
  }
}

// rootLocalized.urlPatterns('it');
// rootLocalized.urlPatterns('en').map((x)=>console.log(x));
// console.log('candid:', rootLocalized.makePath('candidaturess', 'it'));
