import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { ThemeProvider } from '@mui/material/styles';
import { AppManagerProvider } from 'apps/AppManagerProvider';
import { AuthContextProvider } from 'components/contexts/AuthCtx';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloCoreProvider } from '_apollo/ApolloProvider';
// import ScrollToTop from 'components/atoms/ScrollToTop/ScrollToTop';
import { createBrowserHistory } from 'history';
import { ConfirmProvider } from 'material-ui-confirm';

import { createTheme } from '@mui/material/styles';
import { Router } from 'react-router';
import webErrorLink from 'services/apollo/errorLink';
import { firebaseAppAuth } from 'services/firebase';
import 'services/gtm';
// import { HotjarProvider } from 'services/hj/index';
import 'services/sentry';
import 'utils/localizations/i18next';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from 'utils/ErrorBoundary';

const Root: React.FC = () => {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={createTheme()}>
        <Router history={createBrowserHistory()}>
          <ApolloCoreProvider
            uri={process.env['REACT_APP_APOLLO_URI'] as string}
            uriWs={process.env['REACT_APP_APOLLO_WS_URI'] as string}
            firebaseAppAuth={firebaseAppAuth}
            errorCallback={webErrorLink}
          >
            {/* <ScrollToTop> */}
            {/* <I18NUrlProvider> */}
            <AuthContextProvider>
              <ConfirmProvider>
                <AppManagerProvider />
              </ConfirmProvider>
            </AuthContextProvider>
            {/* </I18NUrlProvider> */}
            {/* </ScrollToTop> */}
          </ApolloCoreProvider>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const renderApp = () => {
  const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
  renderMethod(<Root />, document.getElementById('root'));
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('.', renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
