import Url from '../url';

import {
  makeProfileUrls,
  authUrls,
  makeAccountUrls,
  makeCourseDashboardDetailUrls,
} from './shared_conf';

const surveysUrls: any[] = [
  // new Url('questionnaire', 'survey-questionnaire', [], {
  //   it: 'questionario',
  // }),
  new Url(':id/new-response', 'survey-new-response', []),
  // new Url('result', 'surveys-result', [], { it: 'risultato' }),
];

const staffUrls: any[] = [
  new Url('users', 'staff-users', []),
  new Url('users/:id', 'staff-users-detail', []),
];
export const courseDashboardUrls: Url[] = [
  new Url(
    ':courseId',
    'dashboard-course-detail',
    makeCourseDashboardDetailUrls(),
  ),
];
const courseUrls: any[] = [
  new Url('', 'course-list'),
  new Url(':slug', 'course-overview'),
  new Url(':slug/_finish', 'course-finish'),
  new Url(':slug/:module_slug', 'course-module'),
];

const rootUrls: Url[] = [
  new Url('', 'home'),
  new Url('pricing', 'pricing'),
  new Url('about', 'about'),
  new Url('wizard', 'wizard', []),
  new Url('invitation/:token', 'invitation', []),
  new Url('surveys', 'surveys', surveysUrls),

  new Url('__auth__', 'auth', authUrls),
  new Url('profile', 'profile', makeProfileUrls()),
  new Url('account', 'account', makeAccountUrls()),
  new Url('dashboard/course', 'dashboard-course', courseDashboardUrls),
  new Url('course', 'course', courseUrls),
  new Url('staff', 'staff', staffUrls),
  new Url('coming-soon', 'coming-soon'),
  new Url('company-registration', 'company-registration'),
];

// const routerLocalized: Url = new Url(`/:lang(${langsRegex})`, 'locale', rootUrls);
const rootUrl: Url = new Url(``, 'locale', rootUrls);

export default rootUrl;
export { rootUrl };
