import Url from '../url';
import {
  makeProfileUrls,
  authUrls,
  makeCourseDashboardDetailUrls,
  makeAccountUrls,
} from './shared_conf';

const courseAdminDetailsUrls: any[] = [
  // new Url('', 'course-list'),
  new Url('modules/:id', 'admin-course-detail-module-detail'),
  new Url('modules', 'admin-course-detail-modules'),
  new Url('admission/:id', 'admin-course-detail-admission-user-detail'),
  new Url('admission', 'admin-course-detail-admission'),
  new Url('invitations', 'admin-course-detail-invitation'),
  new Url('dev', 'admin-course-detail-dev'),
];

const rootUrls: Url[] = [
  new Url('', 'course-overview'),
  new Url('_finish', 'course-finish'),
  new Url(':module_slug', 'course-module'),
  new Url('__emergency-chat', 'emergency-chat'),
  new Url('__admin__', 'admin-course-detail', courseAdminDetailsUrls),
  new Url(
    '__dashboard__',
    'dashboard-course-detail',
    makeCourseDashboardDetailUrls(),
  ),
  new Url('__auth__', 'auth', authUrls),
  new Url('__profile__', 'profile', makeProfileUrls()),
  new Url('account', 'account', makeAccountUrls()),
  new Url('', 'home'),
  // ...dashboardUrls,
];

// const routerLocalized: Url = new Url(`/:lang(${langsRegex})`, 'locale', rootUrls);
const rootUrl: Url = new Url(``, 'locale', rootUrls);

export default rootUrl;
export { rootUrl };
